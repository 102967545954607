import { Box, Center, Icon } from "@chakra-ui/react";
import { FiLogIn } from "react-icons/fi";
import { useTranslation } from "src/hooks/useTranslation";
import { Login } from "./page";

export default function Page() {
  const t = useTranslation();

  return (
    <>
      <Center mb={6} textAlign="center" fontSize={20} fontWeight={600}>
        <Icon as={FiLogIn} ml={-8} w={6} mr={2} />
        <Box>{t("signIn")}</Box>
      </Center>

      <Box>
        <Login />
      </Box>
    </>
  );
}
